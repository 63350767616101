import { Link } from '@blissbook/ui/lib'
import { BackgroundShapeGrid } from '@blissbook/ui/lib/shapes/grid'

import React from 'react'
import { Layout, MaxWidthContainer, Section, Typography } from '../components'

// Extend the Window interface to include Intercom
declare global {
  interface Window {
    Intercom: (command: string, message: string) => void
  }
}

function showMessages() {
  window.Intercom(
    'showNewMessage',
    'Hello! I would like to make a data privacy request.',
  )
}

export default () => (
  <Layout
    title='Blissbook Data Privacy Request'
    description={'Data Privacy and GDPR Request Form'}
  >
    <Section className='container py-lg'>
      <BackgroundShapeGrid
        alignX='left'
        alignY='top'
        className='tw-hidden md:tw-block'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: -1, y: 2, type: 'wedge', color: 'blurple-500', rotate: 180 },
          { x: -1, y: 3, type: 'square', color: 'aqua-700' },
          {
            x: 0,
            y: 3,
            type: 'square',
            color: 'aqua-500',
            className: 'tw-hidden lg:tw-block',
          },
          { x: -1, y: 4, type: 'wedge', color: 'blurple-700' },
          { x: -1, y: 6, type: 'square', color: 'blurple-300' },
          { x: -1, y: 7, type: 'square', color: 'purple-500' },
          { x: 0, y: 7, type: 'wedge', color: 'purple-300', rotate: 180 },
          { x: 0, y: 8, type: 'square', color: 'red-500' },
          { x: 0, y: 9, type: 'square', color: 'red-300' },
          { x: -1, y: 10, type: 'square', color: 'sunshine-700' },
        ]}
      />
      <BackgroundShapeGrid
        alignX='right'
        alignY='top'
        className='tw-hidden md:tw-block xl:tw-hidden'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: -1, y: 3, type: 'square', color: 'sunshine-700' },
          { x: -1, y: 4, type: 'wedge', color: 'sunshine-500' },
        ]}
      />
      <BackgroundShapeGrid
        alignX='right'
        alignY='top'
        className='tw-hidden xl:tw-block'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: 0, y: 3, type: 'square', color: 'sunshine-700' },
          { x: 0, y: 4, type: 'wedge', color: 'sunshine-500' },
        ]}
      />
      <Typography variant='h4' className='text-center tw-mx-auto'>
        GDPR, CCPA, &amp; Data Privacy
      </Typography>
      <Typography
        variant='h1'
        className='text-center tw-mx-auto tw-my-10'
        style={{ maxWidth: 550 }}
      >
        Exercise your data privacy rights.
      </Typography>
      <MaxWidthContainer className='tw-mx-auto' xs={550}>
        <Typography variant='p2'>
          <p>If you want to exercise your data privacy rights to:</p>
          <ul>
            <li>Delete your personal data</li>
            <li>Update inaccuracies with your personal data</li>
            <li>Request a copy of your personal data</li>
            <li>Make other privacy requests</li>
          </ul>
          <p>
            Submit your request and we will do our best to respond promptly.
          </p>
          <p>
            We may request additional information to enable us to comply with
            your request. If you fail to provide required information, we may
            not be able to fulfill your request.
          </p>
          <p>
            The information you supply, including in this form, will only be
            used for the purpose of responding to your request.
          </p>
          <p>
            To learn more about our data privacy practices and your rights under
            data privacy laws, please see the{' '}
            <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
              Blissbook Privacy Policy
            </Link>
            .
          </p>
        </Typography>
        <Link className='btn btn-sm btn-primary tw-mt-6' onClick={showMessages}>
          Make A Data Privacy Request
        </Link>
      </MaxWidthContainer>
    </Section>
  </Layout>
)
